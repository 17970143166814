<template>
  <div id="home">

    <div class="content">
      <div class="title">
        Call Inquiries
      </div>
      <div class="center">
        <el-date-picker
          v-model="value"
          type="daterange"
          :clearable="false"
          @change="changeTime"
          placement="bottom-start">
        </el-date-picker>
      </div>
      <el-table
        v-loading="table_loading"
        :data="tableData"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          min-width="300"
          align="center"
          label="Date & Time">
          <template slot-scope="scope">
            {{moment(scope.row.call_start_datetime).format('MM/DD/YY, HH:mm:ss A')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          align="center"
          prop="phone_from"
          label="Mobile Phone">
        </el-table-column>
        <el-table-column
          prop="category"
          align="center"
          min-width="200"
          label="Category">
        </el-table-column>
        <el-table-column
          min-width="200"
          align="center"
          label="Conversion">
          <template slot-scope="scope" v-if="scope.row.conversion">
            {{scope.row.conversion ? 'Yes' : 'No'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="summary"
          align="center"
          min-width="200"
          label="Summary">
        </el-table-column>
        <el-table-column
          label="Action"
          align="center"
          min-width="200">
          <template slot-scope="scope">
            <el-link type="primary" title="Account Detail" @click="show_edit(scope.row.call_sid)"><i class="font-size-16 el-icon-search"></i></el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="page_size"
          :total="totalRows">
        </el-pagination>
      </div>
      <show ref="viewForm"></show>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import Show from './callInquiries/show'
  import moment from 'moment/moment';
  export default {
    name: 'CallInquiries',
    components: {
      Show
    },
    data() {
      return {
        value:[moment().add(-1,'months').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
        totalRows: 0,
        current_page: 1,
        page_size: 10,
        tableData: [],
        table_loading: false
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){
      this.getDatas(1)
    },
    methods:{
      moment,
      show_edit(id) {
        this.$refs.viewForm.init(id)
      },
      changeTime(e){
        this.getDatas(1)
      },
      getDatas(initSize) {
        if(initSize == 1) {
          this.current_page = 1
        } else {
          this.current_page = initSize
        }
        this.table_loading = true
        getActionApi("/inquiry/call-inquiry?size=" + this.page_size + "&page=" + this.current_page,{date_from:moment(this.value[0]).format('YYYY-MM-DD'),date_to:moment(this.value[1]).format('YYYY-MM-DD')}).then((res) => {
          this.tableData = res.data
          this.totalRows = res.page.totalElements
        }).catch((err) => {

        }).finally((res) => {
          this.table_loading = false
        })
      },
      handleCurrentChange(val) {
        this.getDatas(val)
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .content{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;align-items: center;}
  .title div *{padding:10px 16px;font-size: 14px;}
  .pager{display: flex;justify-content: center;}
  .font-size-16 {font-size: 20px;}
  .margin-top-switch {margin-top: 10px;}

  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .headImageShow {width: 76px;height: 76px;border-radius: 4px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .title div{margin-top:20px;}
    .center{display: flex;justify-content: center;}
    .content{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
