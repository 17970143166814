<template>
  <div id="home" v-loading="loading">
    <div class="torbar">
      <div @click="changeType(0)" :class="type == 0 ? 'act' : ''">
        <img src="../assets/imgs/icons/icon_pro6.png"/>
        Google
      </div>
      <div @click="changeType(1)" :class="type == 1 ? 'act' : ''">
        <img src="../assets/imgs/icons/icon_pro5.png"/>
        Yelp
      </div>
    </div>
    <div class="reputation" v-if="type == 0">
      <div class="reviews" v-loading="mainLoading">
        <div class="reviewItem">
          <img src="../assets/imgs/icons/icon_pro6.png"/>
          <div class="left">
            <h1>Google</h1>
            <p><b>{{mainInfo.google.user_ratings_total}}</b>Reviews</p>
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="mainInfo.google.rating" disabled>
            </el-rate>
            <span>{{mainInfo.google.rating}} / 5</span>
          </div>
        </div>
        <div>
          <div class="item">
            <h3>{{googleCount}}</h3>
            <p>
              New Review
              <span>(Last 30 Days)</span>
            </p>
          </div>
          <div class="item">
            <h3>{{mainInfo.google.user_ratings_total}}</h3>
            <a>Total</a>
          </div>
        </div>
      </div>
      <h2 class="h2">Reviews</h2>
      <!-- <div class="total">
        <div class="div">{{mainInfo.google.rating.toFixed(1)}}<a>/5.0 Average rating</a></div>
        <el-rate :colors="['#5856D6','#5856D6','#5856D6']"
          v-model="mainInfo.google.rating" disabled>
        </el-rate>
      </div> -->
      <div class="starList">
        <div v-for="(item,index) in googleList" :key="index">
          <div class="item">
            <div class="left">
              <img class="head" :src="item.reviewer_profile_url"/>
              <p>
                {{item.reviewer_name}}
                <span>{{item.review_contents}}</span>
              </p>
            </div>
            <div class="right">
              <img src="../assets/imgs/icons/icon_pro6.png"/>
              <div class="div">
                <el-rate v-model="item.review_rate" disabled>
                </el-rate>
                {{item.review_rate}} / 5
                <span>{{moment(item.review_created_datetime).format('MM/DD/YY, HH:mm:ss A')}}</span>
              </div>
            </div>
            <a class="link" target="_blank" :href="item.review_link"><i class="el-icon-top-right"></i>Open Review</a>
          </div>
          <div class="itemResponse" v-if="item.response_contents">
            <i class="responseIcon el-icon-arrow-down"></i>
            <h2>Dental Solutions</h2>
            <p>{{item.response_contents}}</p>
          </div>
        </div>
      </div>
      <div class="all" v-loading="listLoading" v-if="googleNextToken">
        <span>
          <i class="el-icon-right"></i>
        </span>
        <b @click="queryGoogleList">More review</b>
      </div>
    </div>
    <div class="reputation" v-if="type == 1">
      <div class="reviews" v-loading="mainLoading">
        <div class="reviewItem">
          <img src="../assets/imgs/icons/icon_pro5.png"/>
          <div class="left">
            <h1>Yelp</h1>
            <p><b>{{mainInfo.yelp.user_ratings_total}}</b>Reviews</p>
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="mainInfo.yelp.rating" disabled>
            </el-rate>
            <span>{{mainInfo.yelp.rating}} / 5</span>
          </div>
        </div>
        <div>
          <div class="item">
            <h3>{{yelpCount}}</h3>
            <p>
              New Review
              <span>(Last 30 Days)</span>
            </p>
          </div>
          <div class="item">
            <h3>{{mainInfo.yelp.user_ratings_total}}</h3>
            <a>Total</a>
          </div>
        </div>
      </div>
      <h2 class="h2">Reviews</h2>
      <!-- <div class="total">
        <div class="div">{{mainInfo.google.rating.toFixed(1)}}<a>/5.0 Average rating</a></div>
        <el-rate :colors="['#5856D6','#5856D6','#5856D6']"
          v-model="mainInfo.google.rating" disabled>
        </el-rate>
      </div> -->
      <div class="starList">
        <div v-for="(item,index) in yelpList" :key="index">
          <div class="item">
            <div class="left">
              <img class="head" :src="item.reviewer_profile_url"/>
              <p>
                {{item.reviewer_name}}
                <span>{{item.review_contents}}</span>
              </p>
            </div>
            <div class="right">
              <img src="../assets/imgs/icons/icon_pro5.png"/>
              <div class="div">
                <el-rate v-model="item.review_rate" disabled>
                </el-rate>
                {{item.review_rate}} / 5
                <span>{{moment(item.yelp_review_datetime).format('MM/DD/YY, HH:mm:ss A')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="all" v-loading="listLoading" v-if="yelpPageCount == -1 || yelpPageCount >= page">
        <span>
          <i class="el-icon-right"></i>
        </span>
        <b @click="queryYelpList">More review</b>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  export default {
    name: 'ReviewManagement',
    components: {

    },
    data() {
      return {
        type:0,
        loading:false,
        mainLoading:false,
        listLoading:false,
        validate:{},
        mainInfo:{google:{},yelp:{}},
        googleCount:0,
        googleList:[],
        googleNextToken:null,
        yelpList:[],
        yelpHaveNext:true,
        yelpCount:0,
        page:1,
        yelpPageCount:-1
      }
    },
    computed: {

    },
    created(){
      if(this.$route.query.type)
        this.type = this.$route.query.type
      this.loadDate()
    },
    methods:{
      moment,
      loadDate(){
        this.loading = true
        getActionApi('/review/verification').then((res) => {
          this.validate = res.data
          this.changeType(this.type)
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message)
        }).finally((res) => {
          this.loading = false
        });

      },
      loadMain(){
        this.mainLoading = true
        getActionApi('/review/main').then((res) => {
          this.mainInfo = res.data
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message)
        }).finally((res) => {
          this.mainLoading = false
        });
      },
      changeType(type){
        this.type = type
        if(type == 0){
          if(!this.validate.google){
            this.$alert('Please contact to customer service to add your places', 'message', {
              confirmButtonText: 'Support',
              callback: action => {
                this.$router.push('/support');
              }
            });
          }else{
            this.loadMain()
            this.googleList = []
            this.googleCount = 0
            this.queryGoogleList()
            this.queryGoogleCount()
          }
        }else{
          if(!this.validate.yelp){
            this.$alert('Please contact to customer service to add your places', 'message', {
              confirmButtonText: 'Support',
              callback: action => {
                this.$router.push('/support');
              }
            });
          }else{
            this.loadMain()
            this.yelpCount = 0
            this.page = 1
            this.yelpList = []
            this.queryYelpList()
            this.queryYelpCount()
          }
        }
      },
      async queryGoogleCount(){
        let tokenStr = null
        do{
          let res = await getActionApi('/review/google',{next_page_token:tokenStr})
          tokenStr = res.data.next_page_token
          const list = res.data.reviews
          for(let i = 0; i < list.length; i++){
            if(moment(list[i].review_created_datetime).isAfter(moment().add(-30,'days'))){
              this.googleCount = this.googleCount + 1
            }else{
              return
            }
          }
        }while(tokenStr != null && tokenStr != '')
      },
      queryGoogleList(){
        this.listLoading = true
        getActionApi('/review/google',{next_page_token:this.googleNextToken}).then((res) => {
          this.googleNextToken = res.data.next_page_token
          this.googleList = this.googleList.concat(res.data.reviews)
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message)
        }).finally((res) => {
          this.listLoading = false
        });
      },
      async queryYelpCount(){
        let page = 1
        let pageCount = -1
        do{
          let res = await getActionApi('/review/yelp',{page:page,size:20})
          const list = res.data.reviews
          pageCount = res.page.totalPage
          for(let i = 0; i < list.length; i++){
            if(moment(list[i].yelp_review_datetime).isAfter(moment().add(-30,'days'))){
              this.yelpCount = this.yelpCount + 1
            }else{
              return
            }
          }
          page ++
        }while(pageCount >= page)
      },
      queryYelpList(){
        this.listLoading = true
        getActionApi('/review/yelp',{page:this.page,size:20}).then((res) => {
          this.yelpPageCount = res.page.totalPage
          this.yelpList = this.yelpList.concat(res.data.reviews)
          this.page = this.page + 1
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message)
        }).finally((res) => {
          this.listLoading = false
        });
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}

  .filter{background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding: 20px 30px;}
  .filter h3{color:#2D3648;font-size: 20px;margin-bottom:30px;}
  .filter .item{display: flex;justify-content: space-between;align-items: flex-end;}
  .filter .item div{display: flex;}
  .filter .item p{display: flex;flex-direction: column;color: #A0ABC0;margin-right: 20px;}
  .filter .item input{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item select{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item h2{width: 80px;height: 40px;display: flex;justify-content: center;align-items: center;cursor: pointer;background-color: #5856D6;border-radius: 6px;color:#ffffff;font-size: 14px;font-weight: normal;}
  .reputation{display: flex;flex-direction: column;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;}
  .reputation h1{color:#5856D6;font-size: 20px;text-align: right;margin:0 20px 20px 0;font-weight: normal;}

  .reviews{display: flex;justify-content: space-between;align-items: center;}
  .head{width: 80px !important;height: 80px !important;}
  .reviewItem{height:134px;width:500px;box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border-radius:16px;background-color: #ffffff;border-radius: 16px;padding:0 20px;display: flex;align-items: center;}
  .reviewItem img{width:76px;height:76px;flex-shrink: 0;margin-right:15px;}
  .reviewItem .left{width: 100%;display: flex;flex-direction: column;color:#A0ABC0;font-size: 12px;align-items: flex-start;}
  .reviewItem .left h1{font-size: 20px;color: #A0ABC0;margin:0;}
  .reviewItem .left p{font-size:14px;color:#717D96;margin-bottom:5px;}
  .reviewItem .left p b{font-size: 20px;color:#2D3648;margin-right: 10px;}
  .reviewItem .right{flex-shrink: 0;display: flex;align-items: center;flex-direction: column;}
  .reviewItem .right a{color:#A0AEC0;font-size: 12px;margin-bottom: 5px;}
  .reviewItem .right span{color:#4A5468;font-size: 14px;font-weight: bold;margin-top:5px;}
  .h2{color:#2D3748;font-size: 20px;margin-top:30px;}
  .reviews div{display: flex;}
  .reviews .item{flex-direction: column;align-items: center;padding:0 20px;}
  .reviews .item:nth-child(1){border-right: 1px solid #CBD5E0;}
  .reviews .item h3{font-size: 40px;}
  .reviews .item p{display: flex;flex-direction: column;height: 36px;font-size: 14px;color: #717D96;align-items: center;}
  .reviews .item p span{font-size: 12px;}
  .reviews .item a{border-radius: 200px;background-color: #717D96;width: 100px;height: 36px;color: #ffffff;font-size: 16px;display: flex;align-items: center;justify-content: center;}
  .total{display: flex;align-items: center;font-size: 32px;color:#1A202C;font-weight: bold;}
  .total a{color: #5856D6;font-size: 18px;margin-left:10px;margin-right: 20px;}
  .total .div{display: flex;align-items: center;}
  .total div{display: flex;}
  .starList{display: grid;gap: 20px;margin-top:20px;}
  .starList .item .left{width: 100%;display: flex;}
  .starList .item .right{flex-shrink: 0;display: flex;}
  .starList .item{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;display: flex;}
  .starList .item b{width: 80px;height: 80px;background-color: #5D5DFF;font-size: 24px;color:#ffffff;display: flex;align-items: center;justify-content: center;margin-right: 10px;flex-shrink: 0;}
  .starList .item p{display: flex;flex-direction: column;width: 100%;color: #2D3648;font-size: 20px;font-weight: bold;}
  .starList .item p span{font-size: 16px;color: #717D96;font-weight: normal;}
  .starList .item img{width: 30px;height: 30px;flex-shrink: 0;margin-right: 10px;}
  .starList .item .div{display: flex;flex-direction: column;align-items: center;flex-shrink: 0;font-size: 12px;color: #4A5468;height: 80px;justify-content: space-around;}
  .starList .item .div a{width: 100px;height: 36px;display: flex;align-items: center;justify-content: center;color: #5856D6;cursor: pointer;margin-top:5px;border:1px solid #5856D6;border-radius: 6px;font-size: 14px;}
  .starList .itemResponse{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;display: flex;margin-top:20px;width: 80%;float: right;display: flex;flex-direction: column;}
  .starList .itemResponse h2{color: #2D3648;font-weight: bold;}
  .starList .itemResponse p{color:#717D96;}
  .responseIcon{position: absolute;font-size: 40px;transform: rotate(45deg);margin-left:-70px;margin-top:-10px;color:#CBD5E0;}
  .all{display: flex;align-items: center;margin-top:20px;}
  .all span{width: 30px;height: 30px;display: flex;justify-content: center;cursor: pointer;align-items: center;background-color: #3E66DF;border-radius: 50%;margin-right: 10px;}
  .all span i{color:#ffffff;font-size: 20px;font-weight: bold;}
  .all b{cursor: pointer;color:#5856D6;font-size: 20px;}
  .torbar{display: flex;align-items: center;}
  .torbar div{width: 100%;display: flex;align-items: center;cursor: pointer;justify-content: center;height: 40px;font-weight: bold;border:1px solid transparent;border-bottom:1px solid #CBD5E0;}
  .torbar .act{background-color: #ffffff;border-radius: 6px 6px 0 0;border: 1px solid #CBD5E0;border-bottom-color:#ffffff;color:#5856D6;}
  .torbar div img{width: 15px;height: 15px;margin-right: 10px;}
  .link{background-color: #5856D6;height: 40px;padding:0 20px;display: flex;align-items: center;color: #ffffff;flex-shrink: 0;align-self: center;margin-left:10px;border-radius: 6px;}
  .link i{margin-right: 10px;}
  @media (max-width: 768px) {
    .reputation .list{gap:20px;grid-template-columns: 1fr;grid-template-rows: 1fr 1fr;}
    .account .item{flex-direction: column;}
    .filter .item div{flex-direction: column;}
    .filter .item select{width: 200px;}
    .filter .item input{width: 200px;}
    .starList .item p{font-size: 16px;}
    .starList .item p span{font-size: 14px;}
    .total{margin-top:20px;flex-direction: column;}
    .reviews{flex-direction: column;}
    .reviews h2{align-self: flex-start;}
    .starList .item{flex-direction: column;}
    .starList .item .right{align-self: flex-end;}
    .reviewItem{width:100%;}
    .link{margin-top:10px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .filter .item select{width: 220px;}
    .filter .item input{width: 220px;}
    .reviewItem{width:360px;}
  }
</style>
