<template>
  <div class="bottom" v-if="type === 0">
    <div class="menus" :class="(!showMenu ? 'menuClose' : '') + ' ' + (!showMenuMobile ? 'menuCloseMobile' : '')">
      <img class="logo pc" src="../assets/imgs/logo_top.png"/>
      <img class="logo mobile" src="../assets/imgs/logo_top_m.png"/>
      <div class="menuList">
        <div class="item" :class="menuTitle == 'Dashboard' ? 'act' : ''" @click="go('/home')">
          <div class="icon">
            <img v-if="menuTitle == 'Dashboard'" src="../assets/imgs/icons/menu1_act.png"/>
            <img v-else src="../assets/imgs/icons/menu1.png"/>
          </div>
          <span>Dashboard</span>
        </div>

        <div class="item" :class="menuTitle == 'CallInquiries' ? 'act' : ''" @click="go('/callInquiries')">
          <div class="icon">
            <img v-if="menuTitle == 'CallInquiries'" src="../assets/imgs/icons/menu6_act.png"/>
            <img v-else src="../assets/imgs/icons/menu6.png"/>
          </div>
          <span>Call Inquiries</span>
        </div>

        <div class="item" :class="menuTitle == 'Reporting' ? 'act' : ''" @click="menuTitle = 'Reporting'">
          <div class="icon">
            <img v-if="menuTitle == 'Reporting'" src="../assets/imgs/icons/menu2_act.png"/>
            <img v-else src="../assets/imgs/icons/menu2.png"/>
          </div>
          <span>Reporting</span>
          <i class="el-icon-arrow-up" v-if="menuTitle == 'Reporting'"></i>
          <i class="el-icon-arrow-down" v-else></i>
        </div>

        <div class="itemChild" v-show="menuTitle == 'Reporting'">
          <div @click="go('/monthlyAnalytics')" :class="childTitle == 'Monthly Analytics' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-s-data"></i></span>
            <a>Monthly Analytics</a>
          </div>
          <div @click="go('/socialMedia')" :class="childTitle == 'Social Media' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-s-platform"></i></span>
            <a>Social Media</a>
          </div>
          <div @click="go('/seo')" :class="childTitle == 'SEO Report' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-bangzhu"></i></span>
            <a>SEO Report</a>
          </div>
          <div @click="go('/reputationReport')" :class="childTitle == 'Reputation Report' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-tickets"></i></span>
            <a>Reputation Report</a>
          </div>
        </div>

        <div class="item" :class="menuTitle == 'Review' ? 'act' : ''" @click="menuTitle = 'Review'">
          <div class="icon">
            <img v-if="menuTitle == 'Review'" src="../assets/imgs/icons/menu3_act.png"/>
            <img v-else src="../assets/imgs/icons/menu3.png"/>
          </div>
          <span>Review</span>
          <i class="el-icon-arrow-up" v-if="menuTitle == 'Review'"></i>
          <i class="el-icon-arrow-down" v-else></i>
        </div>

        <div class="itemChild" v-show="menuTitle == 'Review'">
          <div @click="go('/reviewManagement')" :class="childTitle == 'Review Management' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-connection"></i></span>
            <a>Review Management</a>
          </div>
          <div @click="go('/businessPromoter')" :class="childTitle == 'Business Promoter' ? 'childAct' : ''">
            <span class="icon"><i class="el-icon-collection"></i></span>
            <a>Business Promoter</a>
          </div>
        </div>

        <div class="item" :class="menuTitle == 'Support' ? 'act' : ''"  @click="go('/support')">
          <div class="icon">
            <img v-if="menuTitle == 'Support'" src="../assets/imgs/icons/menu4_act.png"/>
            <img v-else src="../assets/imgs/icons/menu4.png"/>
          </div>
          <span>Support</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getActionApi } from '@/network/manageApi'
  export default {
    name: 'PageMenu',
    props:{
      "type":{type:Number,default:0}
    },
    data() {
      return {
        menuTitle: '',
        childTitle: ''
      }
    },
    computed: {
      ...mapGetters(['showMenu']),
      ...mapGetters(['showMenuMobile']),
    },
    watch: {
      '$route': {
        handler(newRoute) {
          if (!!newRoute.meta) {
            this.menuTitle = newRoute.meta.title
            this.childTitle = newRoute.meta.childTitle
          }
        },
        immediate: true,
      }
    },
    methods: {
      go(url) {
        this.$store.commit('SET_SHOW_MENU_MOBILE', true);
        this.$router.push(url);
      }
    },
  }
</script>

<style scoped>
  .menus{background-color:#F8F9FA;z-index:100;transition: all 0.2s linear;padding:20px;position: fixed;width:288px;height:100vh;left:0;top:0;display: flex;flex-direction: column;align-items: flex-start;}
  .menus .logo{height:40px;margin-left:10px;}
  .menus .menuList{padding-top:20px;display: flex;flex-direction: column;width: 100%;}
  .menus .menuList .item{height:64px;padding:10px;display: flex;align-items: center;margin-top:10px;cursor: pointer;}
  .menus .menuList .act{background-color: #ffffff;box-shadow: 0 3.5px 5.5px 0 rgba(0,0,0,0.06);border-radius: 14px;}
  .menus .menuList .act .icon{background-color: #5856D6 !important;box-shadow:0 0 0 0 #ffffff !important;}
  .menus .menuList .act span{color:#2D3648 !important;font-weight: bold;}
  .menus .menuList .item .icon{width: 40px;height:40px;border-radius: 12px;flex-shrink: 0;background-color: #ffffff;display: flex;justify-content: center;align-items: center;box-shadow: 0 3.5px 3.5px 0 rgba(0,0,0,0.06);}
  .menus .menuList .item .icon img{max-width: 24px;max-height: 24px;}
  .menus .menuList .item span{color:#717D96;font-size:14px;margin-left:14px;width: 100%;}
  .menus .menuList .item i{flex-shrink: 0;color:#71839B;font-size:14px;}
  .menus .menuList .itemChild{padding:10px 10px 0 10px;}
  .menus .menuList .itemChild div{display: flex;align-items: center;color: #717D96;font-size: 14px;cursor: pointer;}
  .menus .menuList .itemChild a{color: #717D96;}
  .menus .menuList .itemChild div .icon{width: 40px;height: 40px;font-size:18px;display: flex;align-items: center;justify-content: center;margin-right:14px;}
  .childAct .icon{color:#000000;}
  .childAct a{color:#000000 !important;}
  .menuClose{width:120px !important;}
  .menuClose .menuList span{display: none;}
  .menuClose .menuList .act{background-color: none !important;}
  .menuClose .menuList .item i{display: none;}
  .menuClose .mobile{display: block;}
  .menuClose .menuList .itemChild a{display: none;}
  .menuClose .pc{display: none;}
  .mobile{display: none;}

  @media (max-width: 768px) {
    .menus{position: fixed;top:-100vh;left:0;width: 100vw;}
    .menuCloseMobile{top:0 !important;width: 100vw !important;}
    .menuCloseMobile .menuList span{display: block;}
    .menuCloseMobile .menuList .act{background-color: block !important;}
    .menuCloseMobile .menuList .item i{display: block;}
    .menuCloseMobile .mobile{display: block;}
    .menuCloseMobile .menuList .itemChild a{display: block;}
    .menuCloseMobile .pc{display: block !important;}
    .mobile{display: none !important;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .menus{width:120px;}
    .menus .menuList span{display: none;}
    .menus .menuList .act{background-color: none !important;}
    .menus .menuList .item i{display: none;}
    .menus .menuList .itemChild a{display: none;}
    .mobile{display: block;}
    .pc{display: none;}
  }
</style>
